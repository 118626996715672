<template>
  <a-slider :value="calculatedVolume" :min="0" :max="100" @change="val=> $props.model.volume = val "/>
</template>
<script>
import Vue from "vue";
// your vue component
export default Vue.extend({
  // access any cell properties here
  props: ["rowIndex", "model", "prop", "column"],
  name: "volume",
  data() {
    return {};
  },
  computed: {
    calculatedVolume() {
      return this.$props.model && this.$props.model.volume ? this.$props.model.volume : 0;
    }
  },
  methods: {},
});
</script>
