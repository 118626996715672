





















































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import svgDelete from '../../../components/svg_saves/user_authority_show/Delete.vue'
import svgUpload from '../../../components/svg_saves/user_authority_show/UpLoad.vue'

@Component({
  components: {
    svgDelete,
    svgUpload
  },
  data() {
    return {
      svgDelete,
      svgUpload
    }
  },
})
export default class GuilderHeadButton extends Vue {
  public windownWidth: number = document.body.clientWidth
  @Prop() public defaultOn!: string
  @Prop() public editModeFLag!: boolean
  public editConformFlag : boolean = false
  public get ranterName() : string {
    const name: any = this.$router.currentRoute.query.ranterName || ''
    return name
  }
  @Watch('$store.state.main.screen.width') public windowHeight() {
    this.windownWidth = document.body.clientWidth
  }
  public headGuild(pathTo) {
    setTimeout(() => {
      // 管理表格是处于编辑状态并且存在修改 -- 不允许跳转
      if (this.$store.state.main.pagePublic.isNoJumping) { return }
      if (this.$store.state.main.userAuthorityShow.hadEditFlag) {  this.editConformFlag = true
      } else  {
        this.$router.push({
          path: '/main/guider-nav-page/tables/' + pathTo,
          query: { ranterName: this.ranterName }
        })
      }
    }, 100)
  }
  public returnAction(nav, action) {
    const outProps = {
      nav,
      action,
    }
    this.$emit('headButAction', outProps)
  }
}
