


















































































































































import { Vue, Component, Watch } from 'vue-property-decorator'

import VGrid, { VGridVueTemplate } from '@revolist/vue-datagrid'
import {
  getDeviceDataIntable,
  getDeviceSettingsInfo,
  downloadDataCsv,
  deleteAll,
  csvFileUpload,
  multiUpdateDevice,
  tableAddDeviceInfo,
} from '../../../interfaces/service/device/deviceHardWare'
import { commitSetLoading } from '../../../store/main/mutations'
import drawerDevice from '../device/DeviceHomeDraw.vue'
import deviceBlacklist from '../device/DeviceHomeBlacklist.vue'
import _ from 'lodash'
import guildHeadBut from '../../../views/main/bottom_guild/GuilderHeadButton.vue'
import svgLock from '../../../components/svg_saves/user_authority_show/Lock.vue'
import svgUnlock from '../../../components/svg_saves/user_authority_show/Unlock.vue'
import svgDelete from '../../../components/svg_saves/user_authority_show/Delete.vue'
import svgDownload from '../../../components/svg_saves/user_authority_show/Download.vue'
import svgUpload from '../../../components/svg_saves/user_authority_show/UpLoad.vue'
import svgEreaser from '../../../components/svg_saves/user_authority_show/Ereaser.vue'
import SelectTypePlugin from '../../../components/SelectColumn'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import volumeComponent from './Volume.vue'
import CryptoJs from 'crypto-js'

@Component({
  components: {
    VGrid,
    drawerDevice,
    deviceBlacklist,
    svgLock,
    svgUnlock,
    svgDelete,
    svgDownload,
    guildHeadBut,
  },
  data() {
    return { svgLock, svgUnlock, svgDelete, svgDownload, svgUpload, svgEreaser,
      select: new SelectTypePlugin(),
      numeric: new NumberColumnType('0.00')
    }
  },

  methods: {
    details(param) {
      // 将点击过的链接存入顶部tags
      let headTags: any = this.$store.state.main.headTagsave
      if (headTags) {
        headTags.push({
          tagName: this.$i18n.tc('device.hardware.device_management') + 'ID:' + param,
          name: 'UserDetail',
          params: { id: param },
        })
      } else {
        headTags = [
          {
            tagName: this.$i18n.tc('device.hardware.device_management') + '_ID:' + param,
            name: 'UserDetail',
            params: { id: param },
          }
        ]
      }

      this.$store.state.main.headTagsave = _.uniqWith(headTags, _.isEqual)
      // 跳转
      this.$router.push({ name: 'UserDetail', params: { id: param } })
    },
  },
  computed: {
    totalPage: {
      get() {
        const countList =
          this.$store.state.main.userAuthorityShow.total_page *
          (this.$store.state.main.userAuthorityShow.limit
            ? this.$store.state.main.userAuthorityShow.limit
            : 7)
        return countList // count * from table to do
      },
    }
  },
})
export default class DeviceHardwareShow extends Vue {
[x: string]: any
  // TABLE ACTION 表格操作 END
  // table data
  // rowHead data 每行行首的数据
  // table edit Mode 表格编辑模式开关
  public tableSaveFlag: string = ''
  public confirmLoading: boolean = false
  // CSV导入方式
  public csvUpLoadModelShow: boolean = false // 显示上传model
  // CSV 文件上传
  public csvFileURL: string = ''
  public csvUploadfileList: any = []
  public snInputCode = ''
  public getDeviceDataList : any = []
  public theRanterIDs = sessionStorage.getItem('ranterId')
  public theWindowH = document.body.clientHeight
  public userType: number = this.$store.state.main.userProfile.user_type
  public theOrgID = sessionStorage.getItem('ranterId')
  public tableEditModeOff: boolean = true
  public downloadOff: boolean = true
  // ------弹出对话框-----------
  public modelVisible: boolean = false
  public fileList: any = []
  // ------弹出对话框-----------
  // 表格分页，过滤，排序情况
  public thePagination: any = null
  public theFilters: any = null
  public theSorter: any = { order: null, field: null }
  // 绑定人员到设备弹窗
  public deviceDetailDrawFlag: boolean = false
  public deviceDetailDrawBlacklistFlag: boolean = false
  public deviceChoseDetail: any = null
  // TABLE ACTION 表格操作
  public deleteIDList : any = []
  public editConformFlag: boolean = false
  public resetConformFlag: boolean = false
  public afterTableEditDataShow: any = []
  public selected: any = []
  // 保存并锁定flag
  public saveLockFlag : boolean = false
  // 表格修改时错误信息
  public erroMessage : any = []
  // 设备初始化值
  public deviceSouceData : any = []
  // 表格排序字段，以及顺序
  public sortProps : string = ''
  public sortRule : string = ''

  public checkFields : any = ['name', 'volume', 'position', 'max_body_temperature', 'min_body_temperature'
      ,'offset_body_temperature', 'alcohol_concentration', 'print_photo', 'print_delay', 'font'
      , 'verification_type', 'func_type', 'door_lock_type', 'notes']
  public funcTypeOption : any = []
  // table filter contains actions
  public filtercontains: any = (cellValue: any, extraValue: any) => {
    if (!cellValue) {
      return false
    }
    if (typeof cellValue !== 'string') {
      cellValue = JSON.stringify(cellValue)
    }
    if (extraValue) {
      return cellValue.indexOf(extraValue) > -1 ? cellValue : null
    } else {
      return cellValue
    }
  }
  public filterconcentration: any = (cellValue) => {
    if (!cellValue) return null
    return cellValue > 999.99 ? 999.99 : cellValue < 0.00 ? 0.00 : parseFloat(Number(cellValue).toFixed(2))
  }
  public filterConfig: any = {
    collection: {
      name: {
        type: 'contains',
      },
    },
    include: ['contains', 'none', 'concentration'],
    customFilters: {
      contains: {
        columnFilterType: 'mycontains', // column filter type id
        name: this.$parent!.$i18n.tc('device.hardware.keyword_search'),
        // @ts-ignore
        func: this.filtercontains,
      },
      none: {
        name: this.$parent!.$i18n.tc('clear'),
        func: () => true,
      },
      concentration: {
        columnFilterType: 'concentration',
        name: this.$parent!.$i18n.tc('device.hardware.keyword_search') || '',
        // @ts-ignore
        func: this.filterconcentration,
      }
    },
  }
  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.confirmLoading = this.$store.state.main.waitloading
  }
  @Watch('$store.state.main.deviceHardWare.deviceData')
  public deviceDataWatch(value) {
    this.downloadOff = value.length === 0
  }
  // table filter actions END
  // table editor function
  get tableColumns() {
    const indexShow = {
      size: 70,
      filter: false,
      columnTemplate: (createElement, props) => {
        return createElement('button', {
          onclick: (e) => {
            // this.tableEditModeOff = !this.tableEditModeOff 解锁编辑模式
            if (!this.tableEditModeOff) {
              this.tableSaveFlag = 'device'
            } else {
              this.tableSaveFlag = ''
            }
          },
          style: {
            height: '20px',
            width: '20px',
            margin: '15px 0 0 0',
          },
          class: 'lockEdit',
        })
      },
      cellTemplate: (createElement, props) => {
        return [
          createElement('button', {
            onclick: (e) => {
              // TODO
            },
            rowIndex: props.rowIndex,
            style: {
              height: '20px',
              width: '20px',
              marginTop: '5px',
              paddingLeft: '15px',
              paddingBottom: '6px',
            },
            class: 'lockEdit',
          }),
          props.rowIndex + 1
        ]
      },
    }
    const checkBoxShow = {
      name: this.$i18n.tc('select_all'),
      type: 'checkbox',
      filter: false,
      readonly: true,
      size: 70,
      columnTemplate: (createElement, props) => {
        return [
          createElement('input', {
            onclick: (e) => {
              this.resetSelectAllCheckBox(null)
              // const itemCheck: NodeListOf<HTMLElement> = document.querySelectorAll('.checkBoxItem')
              // if (this.selected.length >= this.getDeviceDataList.length) {
              //   this.selected = [{}]
              //   itemCheck.forEach((obj) => {
              //     obj['checked'] = false
              //   })
              // } else {
              //   this.selected = this.getDeviceDataList
              //   itemCheck.forEach((obj) => {
              //     obj['checked'] = true
              //   })
              // }
            },
            type: 'checkbox',
            class: 'checkBoxAll',
            style: {
              position: 'fixed',
              border: 'none',
              background: 'none',
              fontWeight: 'bold',
              marginTop: '16px',
            },
          }),
          createElement(
            'span',
            { style: { position: 'fixed', left: '30px' } },
            this.$i18n.tc('select_all')
          )
        ]
      },
      cellTemplate: (createElement, props) => {
        const rowData = props.data[props.rowIndex]
        return [createElement('input', {
          onchange: (e) => {
            // if (e.target.checked) {
            //   this.selected.push(props.data[props.rowIndex])
            // } else {
            //   this.selected = _.pull(this.selected, props.data[props.rowIndex])
            // }
          },
          checked: false, // _.indexOf(this.selected, props.data[props.rowIndex]) > -1,
          type: 'checkbox',
          style: {
            height: '13px',
            width: '13px',
            marginTop: '5px',
          },
          class: 'checkBoxItem',
          rIdx: rowData.id
        }) , ' ' + (props.rowIndex + 1)
        ]
      },
    }

    const ableVolume = {
      name: this.$i18n.tc('device.hardware.volume'),
        prop: 'volume',
        size: 120,
        sortable: true,
        readonly: true,
        cellTemplate: VGridVueTemplate(volumeComponent)
    }

    const unableVolume = {
      name: this.$i18n.tc('device.hardware.volume'),
        prop: 'volume',
        size: 120,
        sortable: true,
        readonly: true
    }

    return [
      this.tableEditModeOff ? indexShow : checkBoxShow,
      {
        name: '-',
        prop: 'id',
        filter: false,
        size: 1,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              display: 'none',
            }
          }
        }
      },
      {
        name: this.$i18n.tc('device.hardware.name'),
        prop: 'name',
        filter: 'mycontains',
        editor: 'nameEdit',
        sortable: true,
        size: 180,
        order: 'asc',
      },
      {
        name: this.$i18n.tc('device.hardware.position'),
        prop: 'position',
        filter: 'mycontains',
        size: 128,
        sortable: true
      },
      {
        size: 150,
        name: 'LAN IP',
        prop: 'lan_ip',
        filter: 'mycontains',
        sortable: true,
        readonly: true,
      },
      {
        name: this.$i18n.tc('device.hardware.sdk_version'),
        prop: 'sdk_version',
        filter: 'mycontains',
        size: 400,
        sortable: true,
        readonly: true,
      },
      this.tableEditModeOff ? unableVolume: ableVolume,
      {
        labelKey: 'label',
        valueKey: 'value',
        source: this.getTemperatureOption(),
        columnType: 'select',
        name: this.$i18n.tc('device.hardware.max_body_temperature'),
        prop: 'max_body_temperature',
        initValue: 30,
        size: 120,
        sortable: true
      },
      {
        labelKey: 'label',
        valueKey: 'value',
        source: this.getTemperatureOption(),
        columnType: 'select',
        name: this.$i18n.tc('device.hardware.min_body_temperature'),
        prop: 'min_body_temperature',
        initValue: 30,
        size: 120,
        sortable: true
      },
      {
        labelKey: 'label',
        valueKey: 'value',
        source: this.getOffsetTemperatureOption(),
        columnType: 'select',
        name: this.$i18n.tc('device.hardware.offset_body_temperature'),
        prop: 'offset_body_temperature',
        initValue: 0,
        size: 130,
        sortable: true
      },
      {
        columnType: 'numeric',
        name: this.$i18n.tc('device.hardware.alcohol_concentration'),
        prop: 'alcohol_concentration',
        filter: 'concentration',
        size: 150,
        sortable: true
      },
      {
        labelKey: 'label',
        valueKey: 'value',
        source: [
          { label: this.$i18n.tc('device.hardware.print_y'), value: 1 },
          { label: this.$i18n.tc('device.hardware.print_n'), value: 0 },
        ],
        columnType: 'select',
        name: this.$i18n.tc('device.hardware.print_photo'),
        prop: 'print_photo',
        initValue: 0,
        size: 120,
        sortable: true
      },
      {
        labelKey: 'label',
        valueKey: 'value',
        source: this.getPrintDelayOption(),
        columnType: 'select',
        name: this.$i18n.tc('device.hardware.print_delay'),
        prop: 'print_delay',
        initValue: 0,
        size: 140,
        sortable: true
      },
      {
        labelKey: 'label',
        valueKey: 'value',
        source: [
          { label: this.$i18n.tc('device.hardware.font_default'), value: '0' },
          { label: this.$i18n.tc('device.hardware.font_gothic'), value: 'MS-Gothic.ttf' },
          { label: this.$i18n.tc('device.hardware.font_mincho'), value: 'MSMINCHO.ttf' },
        ],
        columnType: 'select',
        name: this.$i18n.tc('device.hardware.font'),
        prop: 'font',
        initValue: '0',
        size: 120,
        sortable: true
      },
      {
        labelKey: 'label',
        valueKey: 'value',
        source: [
          // { label: '', value: null },
          { label: this.$i18n.tc('device.hardware.verification_face'), value: 'A_0' },
          { label: this.$i18n.tc('device.hardware.verification_prevention'), value: 'A_2' },
          { label: this.$i18n.tc('device.hardware.verification_face_scancode'), value: 'A_3' },
          { label: this.$i18n.tc('device.hardware.verification_face_prevention'), value: 'B_1' },
        ],
        columnType: 'select',
        name: this.$i18n.tc('device.hardware.verification_type'),
        prop: 'verification_type',
        size: 160,
        sortable: true
      },
      {
        labelKey: 'label',
        valueKey: 'value',
        cellTemplate: (h, p) => {
          const label = this.getFuncTypeLabel(p.model)
          const style = {
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '5px 4px 0 4px',
            borderColor: 'transparent',
            borderTopColor: 'initial',
            display: 'inline-block',
            verticalAlign: 'middle',
            opacity: '.4'
          }
          
          return [
            h('div', { class: 'cell-value-wrapper' }, label),
            h('span', { class: { 'arrow-down': true } }, [
            h('span', { ...{ style } })
          ])]
          // return h('label', undefined, undefined, label)
        },
        source: this.funcTypeOption,
        columnType: 'select',
        name: this.$i18n.tc('device.hardware.func_type'),
        prop: 'func_type',
        initValue: 0,
        size: 120,
        sortable: true
      },
      {
        labelKey: 'label',
        valueKey: 'value',
        source: [
          { label: this.$i18n.tc('device.hardware.door_lock_none'), value: 0 },
          { label: this.$i18n.tc('device.hardware.door_lock_normal_open'), value: 1 },
          { label: this.$i18n.tc('device.hardware.door_lock_normal_close'), value: 2 },
        ],
        columnType: 'select',
        name: this.$i18n.tc('device.hardware.door_lock_type'),
        prop: 'door_lock_type',
        size: 160,
        sortable: true
      },
      {
        name: this.$i18n.tc('device.hardware.notes'),
        prop: 'notes',
        filter: 'mycontains',
        size: 220,
        sortable: true,
      },
      {
        filter: false,
        readonly: true,
        size: 136,
        pin: 'colPinEnd',
        cellTemplate: (createElement, props) => {
          return createElement(
            'button',
            {
              onclick: (e) => {
                // this.deviceChoseDetail = props.data[props.rowIndex]
                this.deviceChoseDetail = this.getRealDeviceChoseDetail(props.rowIndex)
                this.deviceDetailDrawFlag = true
              },
              style: {
                display: this.tableEditModeOff ? 'block' : 'none',
                width: '100%',
                height: '32px',
                color: '#00B239',
                border: 'none',
                backgroundColor: '#D6FFC2',
                borderRadius: '5px',
                textAlign: 'center',
                borderTop: '1px solid #fff',
                borderBottom: '1px solid #fff',
              },
            },
            this.$i18n.tc('device.hardware.bind')
          )
        },
      },
      {
        filter: false,
        readonly: true,
        size: 136,
        pin: 'colPinEnd',
        cellTemplate: (createElement, props) => {
          return createElement(
            'button',
            {
              onclick: (e) => {
                // this.deviceChoseDetail = props.data[props.rowIndex]
                this.deviceChoseDetail = this.getRealDeviceChoseDetail(props.rowIndex)
                this.deviceDetailDrawBlacklistFlag = true
              },
              style: {
                display: this.tableEditModeOff ? 'block' : 'none',
                width: '100%',
                height: '32px',
                color: '#00B239',
                border: 'none',
                backgroundColor: '#D6FFC2',
                borderRadius: '5px',
                textAlign: 'center',
                borderTop: '1px solid #fff',
                borderBottom: '1px solid #fff',
              },
            },
            this.$i18n.tc('device.hardware.blacklist')
          )
        },
      },
      {
        name: this.$i18n.tc('device.hardware.type'),
        prop: 'type',
        filter: 'mycontains',
        sortable: true,
        size: 150,
        readonly: true,
      },
      {
        name: 'LAN MAC',
        prop: 'lan_mac',
        filter: 'mycontains',
        sortable: true,
        size: 160,
        readonly: true,
      },
      {
        name: 'SN',
        prop: 'sn',
        filter: 'mycontains',
        size: 160,
        sortable: true,
        readonly: true,
        cellTemplate: (createElement, props) => {
          return createElement(
            'span',
            {},
            // 这里sn番号只显示后7位，包含-号
            props.data[props.rowIndex].sn && props.data[props.rowIndex].sn.length > 7
              ? props.data[props.rowIndex].sn.substring(props.data[props.rowIndex].sn.length - 7)
              : props.data[props.rowIndex].sn
          )
        },
      },
      {
        name: this.$i18n.tc('device.hardware.chip_id'),
        prop: 'chip_id',
        filter: 'mycontains',
        size: 120,
        sortable: true,
        readonly: true,
      },
      {
        name: this.$i18n.tc('device.hardware.hardware_version'),
        prop: 'hardware_version',
        filter: 'mycontains',
        size: 120,
        sortable: true,
        readonly: true,
      },
      {
        name: this.$i18n.tc('device.hardware.org'),
        prop: 'org_ids',
        filter: 'mycontains',
        sortable: true,
        readonly: true,
        size: 180,
        cellTemplate: (createElement, props) => {
          return createElement(
            'span',
            {},
            this.orgNameShowAdjust(props.data[props.rowIndex].org_ids)
          )
        },
      }
    ]
  }
  public async afterTableEditDevice(data) {
    const theSource = _.cloneDeep(await this.deviceSouceData)
    // flag为false时是edit模式
    if (this.tableEditModeOff) {
      this.$message.error(this.$i18n.tc('device.hardware.use_edit_mode_msg'))
      await getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
      this.getDeviceDataList = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
    } else {
      const afterEditSaveZone : any[] = this.afterTableEditDataShow
      let target : any
      if (data.detail.models) {
        target = _.cloneDeep(await data.detail.models)
      } else {
        target = _.cloneDeep(await  { 0: data.detail.model })
      }
      _.forIn(target, (e: any , key : any) => {
         this.checkAfterEdit(data.detail , key , true)
         const prop = data.detail.data ? 'name' : data.detail.prop
         const findKey = target[key].id
         const source =  _.find(theSource, (o : any) =>
               o.id === findKey)
         let updateData = target[key][prop]
         // 联动清空
         if (prop === 'verification_type' && !_.find(this.getFuncTypeOpion(updateData), { value: target[key].func_type })) {
            data.detail.model.func_type = 0
            target[key].func_type = 0
         }
         // 门禁认证设置为防疫时，功能区分固定设置为酒精
         //if (prop === 'verification_type' && updateData === 'A_2') {
         //   data.detail.model.func_type = 3
         //   target[key].func_type = 3
         //}
         // 酒精浓度上限
         if (prop === 'alcohol_concentration') {
            updateData = updateData ? isNaN(updateData) ? 0.00 : this.filterconcentration(updateData) : null
            data.detail.model.alcohol_concentration = updateData
            target[key].alcohol_concentration = updateData
         }

        if (!this.areObjectsEqual(source, target[key])) {
          _.remove(afterEditSaveZone, (o : any) => e.id === o.id)
          afterEditSaveZone.push(target[key])
          this.$store.state.main.userAuthorityShow.hadEditFlag = true
        } else {
          _.remove(afterEditSaveZone, (o : any) => e.id === o.id)
          if (afterEditSaveZone.length === 0) {
            this.$store.state.main.userAuthorityShow.hadEditFlag = false
          }
        }
         this.erroMessage.forEach((element : any) => {
           this.$message.error(this.$i18n.tc(element))
         })
         this.erroMessage = []
      })
      this.afterTableEditDataShow = [...afterEditSaveZone]
    }
  }
  public tableEditModeFun() {
    if (this.$store.state.main.userAuthorityShow.hadEditFlag) {
      this.editConformFlag = true
    } else {
      this.tableEditModeOff = !this.tableEditModeOff
      if (!this.tableEditModeOff) {
        this.tableSaveFlag = 'device'
      } else {
        this.tableSaveFlag = ''
      }
    }
  }
  public checkAfterEdit(data , key , flag ) {
    if (data.data) {
      this.updateValCheck('name', data.data[key].name ) ? flag = flag : flag = false
    } else {
      this.updateValCheck(data.prop, data.val ) ? flag = flag : flag = false
    }
    return flag
  }
  // 表格确认修改，重置修改
  public headButAction(actionData) {
    if (actionData) {
      switch (actionData.action) {
        case 'save':
          this.saveFunc()
          break
        case 'saveLock':
          this.saveFunc()
          if (this.afterTableEditDataShow.length === 0) {
            this.tableEditModeOff = true
            this.tableSaveFlag = ''
            this.saveLockFlag = false
          }
          this.saveLockFlag = true
          this.resetSelectAllCheckBox(null)
          break
        case 'reset':
          this.resetConformFlag = true
          break
        case 'rowDeleteAll':
          this.rowDeleteAll()
          break
      }
    }
  }
  public saveFunc() {
     // 音量特殊处理
    const theSource = _.cloneDeep(this.deviceSouceData)
    _.forEach(this.getDeviceDataList, (item : any) => {
      const editData = _.find(this.afterTableEditDataShow, { id: item.id })
      const sourceData = _.find(theSource, { id: item.id })
      const isEqual = this.areObjectsEqual(sourceData, item)
      if (!editData && item.volume !== sourceData.volume) {
        _.remove(this.afterTableEditDataShow, (o : any) => item.id === o.id)
        this.afterTableEditDataShow.push(item)
        this.$store.state.main.userAuthorityShow.hadEditFlag = true
      } else if (isEqual && editData) {
        _.remove(this.afterTableEditDataShow, (o : any) => item.id === o.id)
        if (this.afterTableEditDataShow.length === 0) {
          this.$store.state.main.userAuthorityShow.hadEditFlag = false
        }
      }
    })

     if (this.afterTableEditDataShow.length > 0) {
        this.editConformFlag = true
     }
     if (this.deleteIDList.length > 0) {
       if (this.afterTableEditDataShow.length === 0) {
         deleteAll(this.userType, this.deleteIDList) // rowIDSelect;回传删除的ID数组
         this.$message.success(this.$i18n.tc('user.authority.save.success'))
       }
     }
  }
  // table editor function END
  // 绑定人员到设备弹窗
  public async deviceDetailClose(returnData) {
    this.deviceDetailDrawFlag = false
    if (returnData) {
      await getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
      this.getDeviceDataList = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
    }
  }
  public async deviceDetailBlacklistClose(returnData) {
    this.deviceDetailDrawBlacklistFlag = false
    if (returnData) {
      await getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
      this.getDeviceDataList = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
    }
  }
  public csvUpLoadModelCancel() {
    // 确认上传
    this.csvUpLoadModelShow = false
  }
  public csvUpLoadModelOK() {
    // 取消上传
    commitSetLoading(this.$store, true)

    this.csvUpLoadModelShow = false
    commitSetLoading(this.$store, false)
  }
  public async csvUploadFunc(info) {
    if (info.fileList.length > 1) {
      this.csvUploadfileList = info.fileList.slice(-1)
    } else {
      this.csvUploadfileList = info.fileList
    }
    await csvFileUpload(info.file, info.fileList.length, this.theOrgID)

    this.csvFileURL = this.$store.state.main.messagePro.csv_file
    // console.log(this.csvFileURL)
  }
  // CSV导入方式 END

  // 表格排序
  public sortChangeAction(peops) {
    this.sortProps = peops.detail.column.prop
    this.sortRule = peops.detail.order
  }
  // CSV导出方式

  public async csvFilesOut(doc) {
    await downloadDataCsv({sortField : this.sortProps || 'name' , sortOrder : this.sortRule || 'desc'}
    , this.theRanterIDs)
    // 定义文件内容，类型必须为Blob 否则createObjectURL会报错
    const content = new Blob([await this.$store.state.main.messagePro])
    // 生成url对象
    const urlObject: any = window.URL || window.webkitURL || window
    const url = urlObject.createObjectURL(content)
    // 生成<a></a>DOM元素
    const el = document.createElement('a')
    // 链接赋值
    el.href = url
    el.download = this.$i18n.tc('csv_export_filename') + '_device.' + doc
    // 必须点击否则不会下载
    el.click()
    // 移除链接释放资源
    urlObject.revokeObjectURL(url)
  }

  // 翻页,排序... todo
  // private handleTableChange(pagination, filters, sorter) {
  //   this.thePagination = pagination
  //   this.theFilters = filters
  //   this.theSorter = sorter
  //   getDeviceData(
  //     this.userType,
  //     pagination.current,
  //     pagination.pageSize,
  //     sorter.field,
  //     sorter.order,
  //     filters
  //   )
  // }
  // delete row selected
  public rowDeleteAll() {
    this.setSelectedData()
    if (this.selected.length > 0) {
      this.modelVisible = !this.modelVisible
    }
  }
  // delete row selected model
  public async modelHandleOk(e) {
    // deleteAll(this.userType, this.deleteIDList) // rowIDSelect;回传删除的ID数组
    commitSetLoading(this.$store, true)
    this.selected.map((obj : any) =>  {
    this.deleteIDList.push(obj.id)
    _.remove(this.getDeviceDataList, (o : any) => obj.id === o.id)
    })
    this.selected = []
    this.modelVisible = false
    commitSetLoading(this.$store, false)
    this.getDeviceDataList = [...this.getDeviceDataList]
    // 删除后重置全选按钮
    this.resetSelectAllCheckBox(null)
  }
  public updateValCheck(checkItem , checkVal) {
    let flag : boolean = true
    switch (checkItem) {
      case 'name':
        if (checkVal.length > 20) {
          flag = false
          if (_.indexOf(this.erroMessage , 'device.hardware.name.overlength') === -1) {
               this.erroMessage.push('device.hardware.name.overlength')
            }
        }
        if (!checkVal) {
          flag = false
          if (_.indexOf(this.erroMessage , 'device.hardware.name.nullerro') === -1) {
              this.erroMessage.push('device.hardware.name.nullerro')
          }
        }
      break
      case 'position':
        if (checkVal && checkVal.length > 128) {
          flag = false
          if (_.indexOf(this.erroMessage , 'device.hardware.position.overlength') === -1) {
              this.erroMessage.push('device.hardware.position.overlength')
          }
        }
        break
      case 'notes':
        if (checkVal && checkVal.length > 256) {
          flag = false
          if (_.indexOf(this.erroMessage , 'device.hardware.notes.overlength') === -1) {
              this.erroMessage.push('device.hardware.notes.overlength')
          }
        }
        break
      default:
        flag = true
        break
    }
    return flag
  }
  // delete row selected
  // edit table conform
  public async editConformOk() {
    try {
      commitSetLoading(this.$store, true)
      if (this.deleteIDList.length > 0) {
        await deleteAll(this.userType, this.deleteIDList) // rowIDSelect;回传删除的ID数组
      }
      if (this.afterTableEditDataShow.length > 0) {
        let updateData = this.afterTableEditDataShow
        let updateCheckFalg : boolean = true
        
        updateData = updateData.map((data : any) => {
          const res = {}
          this.checkFields.forEach(key => {
            updateCheckFalg = this.updateValCheck(key , data[key]) ? updateCheckFalg : false
            res[key] = data[key]
          })
          res['id'] = data.id
          return res
        })
        if (updateCheckFalg) {
          // 更新时添加设备属性
          updateData = await this.addDeviceSetData(updateData)

          await multiUpdateDevice(updateData, this.theRanterIDs)
          if (this.saveLockFlag) {
            this.tableEditModeOff = true
            this.tableSaveFlag = ''
            this.saveLockFlag = false
          }
          commitSetLoading(this.$store, false)
          this.afterTableEditDataShow = []
          this.editConformFlag = false
          this.$message.success(this.$i18n.tc('user.authority.save.success'))
          await getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
          this.$store.state.main.userAuthorityShow.hadEditFlag = false
          this.deviceSouceData = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
          this.deviceSouceData = [...this.deviceSouceData]
          this.getDeviceDataList = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
          this.getDeviceDataList = [...this.getDeviceDataList]
        } else {
          this.erroMessage.forEach((element : any) => {
            this.$message.error(this.$i18n.tc(element))
          })
          commitSetLoading(this.$store, false)
        }
      } else {
        // 无修改
        this.afterTableEditDataShow = []
        this.editConformFlag = false
        this.$message.success(this.$i18n.tc('user.authority.save.success'))
        await getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
        this.$store.state.main.userAuthorityShow.hadEditFlag = false
        this.deviceSouceData = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
        this.deviceSouceData = [...this.deviceSouceData]
        this.getDeviceDataList = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
        this.getDeviceDataList = [...this.getDeviceDataList]
        commitSetLoading(this.$store, false)
      }
    } catch {
      commitSetLoading(this.$store, false)
      return
    }
  }
  public editConformCancel() {
    this.editConformFlag = false
  }
  public async resetConformOk() {
    commitSetLoading(this.$store, true)
    await  getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
    this.$store.state.main.userAuthorityShow.hadEditFlag = false
    this.getDeviceDataList = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
    commitSetLoading(this.$store, false)
    this.afterTableEditDataShow = []
    this.resetConformFlag = false
    this.selected = []
    this.deleteIDList = []
    // 退出编辑
    this.tableEditModeOff = true
    this.tableSaveFlag = ''
  }
  public resetConformCancel() {
    this.resetConformFlag = false
  }
  public snInput(value) {
    this.snInputCode = value
  }
  public async addTableRow(snCode) {
    tableAddDeviceInfo(this.$store.state.main.userProfile.user_type, {
      name: this.$i18n.tc('device.hardware.add_device'),
      org_ids: [this.theOrgID],
      sn: snCode,
    })
    await getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
    this.getDeviceDataList = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
  }
  // add Table rows END
  // deit table conform END
  public orgNameShowAdjust(array) {
    // 修改org_ids[]中的name显示格式
    if (array) {
      const nameArry = array.map((orgIDs) => {
        return orgIDs.name
    })
      if (array.length > 1) {
        return _.join(nameArry, ',')
      } else {
        return nameArry
      }
    }
    return ''
  }
  // VUE 生命周期
  public async created() {
    this.filtercontains.extra = 'input'
    await getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
    this.deviceSouceData = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
    this.getDeviceDataList = _.cloneDeep(this.$store.state.main.deviceHardWare.deviceData)
  }
  // 恢复全选框当前状态
  public resetSelectAllCheckBox(flag) {
    const itemAll: NodeListOf<HTMLElement> = document.querySelectorAll('.checkBoxAll')
    const isCheck = itemAll.length > 0 ? itemAll[0]['checked'] : false
    const itemCheck: NodeListOf<HTMLElement> = document.querySelectorAll('.checkBoxItem')
    _.forEach(itemCheck, (item : any) => {
      try {
        item['checked'] = isCheck
        if (isCheck) {
          const idx = parseInt(item.getAttribute('rIdx'), 0)
          const checkData = _.find(this.getDeviceDataList, { id: idx })
          if (checkData) {
            this.selected = _.unionBy(this.selected, [checkData], 'id')
          }
        }
      } catch (e) {
        // ingore
      }
    })
    if (!isCheck) {
      this.selected = []
    }
    const that = this
    if (!flag) {
       setTimeout(() => {
        that.resetSelectAllCheckBox(1)
      }, 100)
    }
  }
  // 重新获取所有的勾选的行信息
  public setSelectedData() {
    const itemCheck: NodeListOf<HTMLElement> = document.querySelectorAll('.checkBoxItem')
    const currentSelected: any = []
    const deviceListData: any = [...this.getDeviceDataList]
    _.forEach(itemCheck, (item : any) => {
      try {
        const isCheck = item['checked']
        if (isCheck) {
          const nextNode = item.parentNode.parentNode.children[1] as HTMLElement
          const idx = parseInt(nextNode.innerText, 0)
          const checkData = _.find(deviceListData, { id: idx })
          if (checkData) {
            currentSelected.push(checkData)
          }
        }
      } catch (e) {
        console.error(e)
      }
    })
    this.selected = currentSelected
  }
  // 获取真实选中的详情信息
  public getRealDeviceChoseDetail(rIdx) {
    const itemCheck: NodeListOf<HTMLElement> = document.querySelectorAll('.lockEdit')
    const deviceListData: any = [...this.getDeviceDataList]
    let choseData: any = null
    _.forEach(itemCheck, (item : any) => {
      try {
        const idx = parseInt(item.getAttribute('rowIndex'), 0)
        if (rIdx === idx) {
          const nextNode = item.parentNode.parentNode.children[1] as HTMLElement
          const idV = parseInt(nextNode.innerText, 0)
          const checkData = _.find(deviceListData, { id: idV })
          if (checkData) {
            choseData = checkData
          }
        }
      } catch (e) {
        console.error(e)
      }
    })
    return choseData
  }
  
  public areObjectsEqual(obj1, obj2) {
    // 检查每个键对应的值是否相同
    for (let i = 0; i < this.checkFields.length; i++) {
      const key = this.checkFields[i]
      if (obj1[key] !== obj2[key]) {
        return false
      }
    }
    // 如果上述条件都满足，则对象相同
    return true
  }

  // 体温上限/下限 下拉选项
  public getTemperatureOption() {
    const initData = 30.0
    const res:any = []
    for (let i = 0; i < 151; i++) {
      const data = initData + i * 0.1
      res.push({ label: data.toFixed(1) + '℃', value: data })
    }
    return res
  }
  // 体温校准 下拉选项
  public getOffsetTemperatureOption() {
    const initData = -0.5
    const res:any = []
    for (let i = 0; i < 11; i++) {
      const data = initData + i * 0.1
      const value = data === 0 ? data : parseFloat(data.toFixed(1))
      res.push({ label: value + '℃', value: value })
    }
    return res
  }
  // 延迟打印(秒) 下拉选项
  public getPrintDelayOption() {
    const initData = 0
    const res:any = []
    for (let i = 0; i < 11; i++) {
      const data = initData + i
      res.push({ label: data + 's', value: data * 1000 })
    }
    return res
  }

  public getFuncTypeOpion(type) {
    let res:any = [{ label: '', value: 0 }]
    if (type === 'A_0') {
      res.push({ label: this.$i18n.tc('device.hardware.func_in'), value: 1 })
      res.push({ label: this.$i18n.tc('device.hardware.func_out'), value: 2 })
    } else if (type === 'A_2') {
      res.push({ label: this.$i18n.tc('device.hardware.func_alcohol'), value: 3 })
    }
    return res
  }
  public cellfocusAction(data) {
    if (!this.tableEditModeOff && data.detail.prop === 'func_type') {
      const model = data.detail.model
      this.funcTypeOption = this.getFuncTypeOpion(model.verification_type)
    }
  }
  public getFuncTypeLabel(model) {
    const res:any = [{ label: '', value: 0 }]
    res.push({ label: this.$i18n.tc('device.hardware.func_in'), value: 1 })
    res.push({ label: this.$i18n.tc('device.hardware.func_out'), value: 2 })
    res.push({ label: this.$i18n.tc('device.hardware.func_alcohol'), value: 3 })
    const item = _.find(res, { value: model.func_type })
    if (!item) {
      model.func_type = 0
    } 
    return item? item.label: ''
  }

  public async addDeviceSetData(updateData) {
    updateData = await Promise.all(updateData.map(async (data : any) => {
      const deviceSetData: any = await getDeviceSettingsInfo(data.id)
      if (!deviceSetData || !deviceSetData.id) return data
      const json_info = JSON.parse(deviceSetData.json_info)
      const printerConfig = { 
        'title': data.name, 
        'address': data.position, 
        'alcoholConcentration': data.alcohol_concentration,
        'font': data.font,
        'notes': data.notes,
        'printDelay': data.print_delay,
        'printPhoto': data.print_photo
      }
      if (json_info.printerConfig) {
        json_info.printerConfig = { ...json_info.printerConfig, ...printerConfig }
      } else {
        json_info.printerConfig = printerConfig
      }

      // 解锁设置
      const accessBasicConfig = { 
        'doorLockType': data.door_lock_type
      }
      if (json_info.accessBasicConfig) {
        json_info.accessBasicConfig = { ...json_info.accessBasicConfig, ...accessBasicConfig }
      } else {
        json_info.accessBasicConfig = accessBasicConfig
      }

      const uiConfig:any = { 
        'volume': data.volume, 
        'maxBodyTemperature': data.max_body_temperature, 
        'minBodyTemperature': data.min_body_temperature,
        'offsetBodyTemperature': data.offset_body_temperature,
        'widccLogType': data.func_type
      }
      // 门禁认证设置
      if(data.verification_type && data.verification_type.indexOf("A") == 0){
        uiConfig.accessSingleVerifiMode = parseInt(data.verification_type.substr(2))
        uiConfig.accessVerifiMode = 0
      } else if(data.verification_type && data.verification_type.indexOf("B") == 0){
        uiConfig.accessVerifiMode = parseInt(data.verification_type.substr(2))
      }
      if (json_info.uiConfig) {
        json_info.uiConfig = { ...json_info.uiConfig, ...uiConfig }
      } else {
        json_info.uiConfig = uiConfig
      }
      
      // 设备设定
      data['json_info'] = JSON.stringify(json_info)
      data['md5'] = CryptoJs.MD5(data['json_info']).toString()
      data['sha1'] = CryptoJs.SHA1(data['json_info']).toString()
      return data
    }))
    return updateData
  }
}
