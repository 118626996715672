import { SelectConfig } from "./type";
const style = {
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '5px 4px 0 4px',
    borderColor: 'transparent',
    borderTopColor: 'initial',
    display: 'inline-block',
    verticalAlign: 'middle',
    opacity: '.4'
  };
export const SelectColumnRenderer = (h: any, {model, prop, column}: any): any[] => {
    let col = column as SelectConfig;
    let val = model[prop];
    if (col.labelKey && col.sourceLookup) {
        if (!col.sourceLookup[val] || col.sourceLookup[val][col.labelKey] === '') {
            if (col.initValue !== undefined && col.initValue !== null) {
                val = col.sourceLookup[col.initValue][col.labelKey]
                model[prop] = col.initValue
            } else if (col.sourceLookup.length > 0) {
                val = col.sourceLookup[0][col.labelKey]
                model[prop] = col.sourceLookup[0]
            }
        } else {
            val = col.sourceLookup[val][col.labelKey];
        }
    }
    return [
        h('div', { class: 'cell-value-wrapper' }, val),
        h('span', {
            class: { 'arrow-down': true },
            on: {
            click: (e: MouseEvent) => {
                const ev = new MouseEvent('dblclick', {
                bubbles: true,
                cancelable: true,
                view: window
                });
                (e.target as HTMLElement)?.dispatchEvent(ev);
            }
            }
        }, [
        h('span', { ...{ style } })
      ])
    ];
};
